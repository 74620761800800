import React, { useState, useEffect } from "react"
import "../components/layout.css"
import axios from "axios"
import { Container, Spinner } from "react-bootstrap"
import styled from "styled-components"
import LogoTransfez from "../images/transfez-new-logo-big.svg"

import { Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { ForgotPasswordJson } from "../components/location"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesCircle,
  faMinusCircle,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import Seo from "../components/SEOComponents/Seo"
import { CustomButton } from "../components/Buttons/parent"

function ForgotPassword() {
  const { search } = useLocation()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isSg = location.pathname.includes("/sg")
  const ForgotPasswordContent = ForgotPasswordJson()
  const { Title, Button, Point1, Point2, Placeholder } = ForgotPasswordContent
  const newValue = search.replace("?reset_password_token=", "")
  const [isLoading, setIsLoading] = useState(false)
  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const [isWrong, setIsWrong] = useState(false)
  const [inputForm, setInputForm] = useState({
    password: "",
    password_confirmation: "",
    reset_password_token: newValue || "",
  })

  const isPasswordSame = inputForm.password == inputForm.password_confirmation
  const { isPassedAll, isPasswordSix } = RegexGenerator({
    password: inputForm.password,
  })
  const isPassedAllCondition = isPassedAll && isPasswordSame
  const isTokenEmpty = search == ""

  function onChange(e) {
    let { name, value } = e.target

    const newInputForm = {
      ...inputForm,
      [name]: value,
    }
    setInputForm(newInputForm)
    setIsUnauthorized(false)
    setIsLoading(false)
    setIsWrong(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    const URL_ID = `${process.env.GATSBY_ROOT_URL_ENV}/api/auth/password`
    const URL_SG = `${process.env.GATSBY_SGD_ROOT_URL_ENV}/api/auth/password`
    const URL_ADDRESS = isSg ? URL_SG : URL_ID

    if (isTokenEmpty) {
      setIsUnauthorized(true)
      setIsLoading(false)
      return
    }

    if (isPasswordSame && isPasswordSix) {
      return axios
        .put(URL_ADDRESS, {
          password: inputForm.password,
          password_confirmation: inputForm.password_confirmation,
          reset_password_token: inputForm.reset_password_token,
        })
        .then(res => {
          navigate(isEnglish ? `/en/reset-password` : `/reset-password`)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      setIsWrong(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Seo title="forgot-password" />
      <ContainerForgot>
        <div>
          {/* {isPassedAllCondition ? "required" : "masih blm required"} */}
          <Link to={isEnglish ? `/en/` : `/`}>
            <LogoImg src={LogoTransfez} alt="logo-transfez" />
          </Link>
          <ForgotPasswordRounded>
            <h3>{Title}</h3>
            <form onSubmit={handleSubmit} style={{ marginBottom: 0 }}>
              <FormForPassword>
                <label>{Point1}</label>
                <input
                  onChange={onChange}
                  name="password"
                  type="password"
                  // placeholder={Placeholder}
                  minLength="6"
                  required
                ></input>
              </FormForPassword>
              <FormForPassword>
                <label>{Point2}</label>
                <input
                  onChange={onChange}
                  name="password_confirmation"
                  type="password"
                  // placeholder={Placeholder}
                  minLength="6"
                  required
                ></input>
              </FormForPassword>
              <RequirementList
                isEnglish={isEnglish}
                password={inputForm.password}
              />
              <AlertComponents
                isLoading={isLoading}
                isUnauthorized={isUnauthorized}
                isWrong={isWrong}
              />
              <CustomButton
                type="submit"
                padding="5px"
                width="100%"
                marginTop="20px"
                isDisabled={!isPassedAllCondition}
              >
                {Button}
              </CustomButton>
            </form>
          </ForgotPasswordRounded>
        </div>
      </ContainerForgot>
    </>
  )
}

export default ForgotPassword

const RegexGenerator = ({ password }) => {
  const isOneLowerCaseRegex = /(?=.*[a-z])/
  const isOneUpperCaseRegex = /(?=.*[A-Z])/
  const isOneNumberRegex = /(?=.*\d)/
  const isOneSpecialCharRegex = /[!@#$%^&*]+/

  const isOneLowerCase = isOneLowerCaseRegex.test(String(password))
  const isOneUpperCase = isOneUpperCaseRegex.test(String(password))
  const isOneNumber = isOneNumberRegex.test(String(password))
  const isOneSpecialChar = isOneSpecialCharRegex.test(String(password))
  const isPasswordSix = password.length >= 6

  const isPassedAll =
    isOneLowerCase &&
    isOneUpperCase &&
    isPasswordSix &&
    isOneNumber &&
    isOneSpecialChar
  return {
    isPassedAll,
    isOneLowerCase,
    isOneUpperCase,
    isPasswordSix,
    isOneNumber,
    isOneSpecialChar,
  }
}

const AlertComponents = ({ isWrong, isUnauthorized, isLoading }) => {
  return (
    <>
      {isWrong ? (
        <InlineText>
          <FontAwesomeIcon
            icon={faMinusCircle}
            size="xs"
            style={{ color: "#dc3545" }}
          />
          <small className="text-danger">
            {" "}
            The password and confirmation password do not match.{" "}
          </small>
        </InlineText>
      ) : null}

      {isUnauthorized ? (
        <InlineText>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="xs"
            style={{ color: "#ffc107" }}
          />
          <small className="text-warning"> Unauthorized </small>
        </InlineText>
      ) : null}

      {isLoading ? (
        <InlineText>
          <Spinner animation="border" size="sm" variant="light" />
          <small> Your application is being processed, please wait </small>
        </InlineText>
      ) : null}
    </>
  )
}

const RequirementList = ({ isEnglish, password }) => {
  const {
    isPasswordSix,
    isOneSpecialChar,
    isOneUpperCase,
    isOneLowerCase,
    isOneNumber,
  } = RegexGenerator({ password })
  const data = [
    {
      title: isEnglish ? "6 characters" : "6 karakter",
      desc: "Minimum ",
      condition: isPasswordSix,
    },
    {
      title: isEnglish ? "1 Special character" : "1 karakter spesial",
      desc: " (e.g !@#$%^&*)",
      condition: isOneSpecialChar,
    },
    {
      title: isEnglish ? "1 upper case letter" : "1 huruf besar",
      desc: " (A-Z)",
      condition: isOneUpperCase,
    },
    {
      title: isEnglish ? "1 lower case letter" : "1 huruf kecil",
      desc: " (a-z)",
      condition: isOneLowerCase,
    },
    {
      title: isEnglish ? "1 number" : "1 angka",
      desc: " (0-9)",
      condition: isOneNumber,
    },
  ]
  return (
    <>
      {data?.map((item, index) => {
        const { title, desc, condition } = item
        const isFirst = index === 0
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              marginBottom: 5,
            }}
            key={index}
          >
            <FontAwesomeIcon
              icon={condition ? faCheck : faTimes}
              size="xs"
              style={{ color: "#DB64A0" }}
            />
            <ReqText>
              {isFirst && <ReqSpan>{desc}</ReqSpan>}
              {title} {!isFirst && <ReqSpan>{desc}</ReqSpan>}
            </ReqText>
          </div>
        )
      })}
    </>
  )
}

const ContainerForgot = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  > div {
    text-align: center;
    max-width: 450px;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`

const LogoImg = styled.img`
  width: 180px;
  height: 53px;
  margin-bottom: 1.5rem;
`

const ForgotPasswordRounded = styled.div`
  border-radius: 20px;
  padding: 1.5rem;
  background-color: #102c57 !important;
  > h3 {
    font-size: 1.75rem;
    color: white;
    text-align: center;
    font-family: "GothamMedium";
  }
`

const FormForPassword = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  font-family: "Typefez-reg";
  > label {
    font-size: 12px;
    position: absolute;
    top: 8px;
    left: 10px;
  }
  > input {
    width: 100%;
    padding-top: 30px !important;
    height: 60px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border: 0;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    outline: none;
  }
  > input::placeholder {
    color: #727272;
  }
`

const InlineText = styled.div`
  float: left;
  > small {
    color: white;
    font-family: "Typefez-reg";
  }
`

const ReqText = styled.p`
  font-family: "Typefez-bold";
  margin-bottom: 0px;
  color: white;
`

const ReqSpan = styled.span`
  font-family: "Typefez-reg";
`
